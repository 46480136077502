<template>
  <div class="colonel-privilege">
    <div class="bg">
      <div class="return-wrap" @click="handleReturn">
        <!--        <img class="return-arrow-top"  mode="widthFix" src="https://cdn.cailu88.com/jingxuanshi/return_arrow@2x.png">-->
      </div>
    </div>
    <div class="invite-progress">
      <div class="invite-progress-title">
        <img class="icon" mode="widthFix" src="https://cdn.cailu88.com/jingxuanshi/colonel_privilege_icon@2x.png"/>
        <div>
          <img class="left-icon" mode="widthFix" src="https://cdn.cailu88.com/jingxuanshi/label_left@2x.png"/>
          升级进度
          <img class="right-icon" mode="widthFix" src="https://cdn.cailu88.com/jingxuanshi/label_right@2x.png"/>
        </div>
      </div>
      <div class="invite-progress-container">
        <div class="progress-item" v-for="(item, index) in outMission" :item="item" :index="index" :key="index">
          <div class="title">
            <div class="title-text">{{ item.text }}</div>
            <div class="number">
              <span>{{ item.currentNumber }}</span>
              <span>/{{ item.totalNumber }}kg</span>
            </div>
          </div>
          <div class="progress">
            <van-progress :percentage="item.percent ? (item.percent).toFixed(2) : 0" stroke-width="8" color="#FFEA1C"/>
            <!--            <progress border-radius="50" duration="50" stroke-width="8" percent="{{item.percent}}" activeColor="#" backgroundColor="#F4F4F4" active="true" />-->
          </div>
        </div>
      </div>
      <div class="invite-details">
        <span class="text-button" @click="handleShowDetails">查看任务详情</span>
        <img class="arrow-right" src="https://cdn.cailu88.com/jingxuanshi/black_arrow@2x.png"/>
      </div>
      <button class="button invite-button" type="default" size="default" hover-class="other-button-hover"
              @click="handleInvite">分享鲸选师
      </button>
    </div>
    <div class="colonel-privilege-container">
      <!-- <image class="icon" mode="widthFix" src="https://cdn.cailu88.com/jingxuanshi/colonel_privilege_icon@2x.png"></image>
      <view class="colonel-privilege-title">团长特权</view> -->
      <img class="colonel-privilege-image" mode="widthFix"
           src="https://cdn.cailu88.com/jingxuanshi/equity_3.png"/>
      <div class="about">
        <div class="about-title">
          <img class="left-icon" mode="widthFix" src="https://cdn.cailu88.com/jingxuanshi/label_left@2x.png"></img>
          关于鲸选师
          <img class="right-icon" mode="widthFix" src="https://cdn.cailu88.com/jingxuanshi/label_right@2x.png"></img>
        </div>
        <video
          id="myVideo"
          src="https://cdn.cailu88.com/jingxuanshi/video/presentation.mp4"
          poster="https://cdn.cailu88.com/jingxuanshi/jing_logo_icon@2x.png"
          controls
        ></video>
      </div>
      <img class="colonel-privilege-image colonel-privilege-image-2" mode="widthFix"
           src="https://cdn.cailu88.com/jingxuanshi/become@3x.png"></img>
    </div>
    <!--    v-if="(userLevel  == 1 || userLevel  == 2 || userLevel  == 3) && supervisorWechat"-->
    <div class="footer" @click="handleShowWX">
      <img class="wx-icon" mode="widthFix" src="https://cdn.cailu88.com/jingxuanshi/wx_icon_gold@2x.png"/>
      添加专属导师微信，了解更多团长详情
    </div>
    <div>
      <van-popup v-model="popupVisible" position="bottom" close-icon="close" closeable round
                 :style="{ height: '310px' }">
        <div class="popupColonel">
          <p class="title">我的专属导师</p>
          <p class="textContent">鲸选师为您分配了专属的赚钱导师<br>他们将带您了解掌握鲸选师的最佳赚钱攻略</p>
          <p class="teacherWechat">
            <img class="wechatLogo" src="https://cdn.cailu88.com/jingxuanshi/wechat.png">
            <span class="wechatNumber">微信号：{{ supervisorWechat }}</span>
          </p>
          <button class="copyBtn" v-clipboard:copy="supervisorWechat" v-clipboard:success="handleCopy"
                  v-clipboard:error="onError">复制导师微信号去添加好友
          </button>
        </div>
      </van-popup>
    </div>
    <van-action-sheet v-model="sheetParams.show" class="energy-sheet">
      <p class="title-1">能量值成长说明</p>
      <p class="title-2">分享或获得收益均可增加能量值</p>
      <div class="invite-progress-container">
        <div class="progress-item" v-for="(item, index) in teamMission" :item="item" :index="index" :key="index">
          <div class="title">
            <div class="title-text">{{ item.text }}</div>
            <div class="number">
              <span>{{ item.currentNumber }}</span>
              <span>/{{ item.totalNumber }}kg</span>
            </div>
          </div>
          <div class="progress">
            <van-progress :percentage="item.percent ? (item.percent).toFixed(2) : 0" stroke-width="8" color="#FFEA1C"/>
            <!--            <progress border-radius="50" duration="50" stroke-width="8" percent="{{item.percent}}" activeColor="#" backgroundColor="#F4F4F4" active="true" />-->
          </div>
        </div>
      </div>
      <div class="content-desc">
        <p class="title">分享</p>
        <p class="description">
          分享专属粉丝，能量值增加1000g，
          <br/>
          分享专属粉丝至多获得30kg能量直。
          <br/><br/>
          分享普通粉丝，能量值增加500g，
          <br/>
          分享普通粉丝至多获得45kg能量值。</p>
      </div>
      <div class="content-desc">
        <p class="title">获得收益</p>
        <p class="description">
          确认收货总收益每增加1元，能量值增加10g，
          <br/>
          确认收货总收益至多获得5kg能量值。
          <br/>
          分享越多，能量值增加越快哦！
          <br/>
          赶快分享好友一起来鲸选师享受优惠吧～
        </p>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from 'vue'
import baseUrl from '../../utils/baseUrl'
import { Progress, Popup, Toast, ActionSheet } from 'vant'

const api = require('../../utils/api').api
import { hybrid } from 'hybrid-api'

Vue.use(Progress)
Vue.use(Popup)
Vue.use(Toast)
Vue.use(ActionSheet)

export default {
  name: 'index',
  data () {
    return {
      version: '',
      channel: '',
      pageScrollTop: 0,
      userLevel: 0,
      show_popup_share: false,
      show_popup_poster: false,
      is_first_popup_share: true,
      is_first_popup_poster: true,
      is_allow_save_image: null,
      supervisorWechat: '',
      show_public_box: false,
      popupVisible: false,
      is_first: true,
      sheetParams: {
        show: false,
      },
      outMission: [
        {
          text: '能量值',
          currentNumber: 0,
          totalNumber: 0,
          percent: 0,
        },
      ],
      teamMission: [
        {
          text: '有效专属粉丝',
          currentNumber: 0,
          totalNumber: 0,
          percent: 0,
        }, {
          text: '有效普通粉丝',
          currentNumber: 0,
          totalNumber: 0,
          percent: 0,
        },
        //   {
        //   text: "近30天确认收货团队收入",
        //   currentNumber: 0,
        //   totalNumber: 0,
        //   percent: 0,
        // },
        {
          text: '近30天确认收货总数量',
          currentNumber: 0,
          totalNumber: 0,
          percent: 0,
        }]
    }
  },
  mounted () {
    this.version = this.$route.query.version
    this.channel = this.$route.query.channel
    this.handleGetWhale()
  },
  methods: {
    handleReturn () {
      let url = 'http://user.test.com'
      let params = { name: 'name' }
      hybrid.util.openLink(url, params)// params 是可选的hybrid.util.openLink(url, params);
    },
    handleGetWhale () {
      this.$axios(api.whale, {
        params: {
          id: this.$route.query.id,
          clientType: 2
        }
      }).then(res => {
        this.userLevel = res.data.data.userLevel
        this.supervisorWechat = res.data.data.supervisorWechat
        if (this.userLevel == '2') {
          // 外部显示的能量值
          this.outMission[0].currentNumber = res.data.data.regimentalWeight;
          this.outMission[0].percent = res.data.data.regimentalWeight / res.data.data.regimentalWeightLimit * 100;
          this.outMission[0].totalNumber = res.data.data.regimentalWeightLimit;
          // 详情
          this.teamMission[0].currentNumber = res.data.data.directInvWeight
          this.teamMission[0].percent = res.data.data.directInvWeight / res.data.data.directInvLimit * 100
          this.teamMission[0].totalNumber = res.data.data.directInvLimit
          this.teamMission[1].currentNumber = res.data.data.indirectInvWeight
          this.teamMission[1].percent = res.data.data.indirectInvWeight / res.data.data.indirectInvLimit * 100
          this.teamMission[1].totalNumber = res.data.data.indirectInvLimit
          // this.teamMission[2].currentNumber = res.data.data.regimentalTeamCommission;
          // this.teamMission[2].percent = res.data.data.regimentalTeamCommission / res.data.data.regimentalTeamCommissionLimit * 100;
          // this.teamMission[2].totalNumber = res.data.data.regimentalTeamCommissionLimit;
          this.teamMission[2].currentNumber = res.data.data.thirtyDayWeight
          this.teamMission[2].percent = res.data.data.thirtyDayWeight / res.data.data.thirtyDayWeightLimit * 100
          this.teamMission[2].totalNumber = res.data.data.thirtyDayWeightLimit
        }
      }).catch(err => {
        if (err.data.code == 'USER_UN_LOGIN') {
          let event = 'login'
          let param = {
            backUrl: `${baseUrl.pageUrl}/colonel/privilege`
          }
          this.$h5AppLink(event, param)
        }
      })
    },

    // 显示能量详情
    handleShowDetails(){
      this.sheetParams.show = true;
    },

    // 立即邀请按钮点击事件
    handleInvite () {
      let u = window.navigator.userAgent
      let isIOSApp = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)  // ios终端
      let isAndroidApp = (u.indexOf('Android') > -1) // android
      if (isIOSApp) {
        // 调用原生方法
        window.webkit.messageHandlers.h5_app_link.postMessage(
          JSON.stringify({
            event: 'shareInvite'
          })
        )
      } else {
        window.android.h5_app_link(JSON.stringify({
          event: 'shareInvite'
        }))
      }
    },

    // 导师微信显示
    handleShowWX () {
      this.popupVisible = true
    },

    // 复制口令
    cacheCopy () {
      let u = window.navigator.userAgent
      let isIOSApp = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)  // ios终端
      let isAndroidApp = (u.indexOf('Android') > -1) // android
      if (isIOSApp) {
        // // 调用原生方法
        window.webkit.messageHandlers.h5_app_link.postMessage(
          JSON.stringify({
            event: 'cacheCopy',
            param: {
              content: this.supervisorWechat,
            }
          })
        )
      } else {
        window.android.h5_app_link(
          JSON.stringify({
            event: 'cacheCopy',
            param: {
              content: this.supervisorWechat,
            }
          })
        )
      }
    },

    // 复制导师微信
    handleCopy () {
      Toast.success('复制成功')
      if (this.version > '0.1.5') {
        this.cacheCopy()
      }
    },
    onError () {
      Toast.fail('复制失败')
    },
  },
}
</script>

<style scoped>

/deep/ .van-progress__pivot {
  display: none;
}

.colonel-privilege {
  width: 100%;
  font-size: 0;
  line-height: 0;
  background-color: #ffea1c;
  display: inline-block;
}


.colonel-privilege .return-fixed {
  width: 100%;
  height: 45px;
  background: #ffea1c;
  position: fixed;
  top: 0;
  z-index: 2;
}

.colonel-privilege .return-fixed .title {
  font-size: 20px;
  font-weight: bold;
  line-height: 46px;
  color: #333;
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.colonel-privilege .return-fixed .return-wrap {
  width: 14px;
  padding: 15px 15px 15px 10px;
  display: inline-block;
}

.colonel-privilege .return-fixed .return-wrap .return-arrow {
  width: 9px;
  height: 16px;
}

.colonel-privilege .bg {
  height: 138px;
  /* padding: 32rpx 40rpx 120rpx; */
  background-color: #222;
  background-image: url('https://cdn.cailu88.com/jingxuanshi/jing_bg_3@2x.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

.colonel-privilege .bg .bg-title {
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  line-height: 40px;
}

.return-wrap {
  padding: 11px 15px 11px 10px;
  position: absolute;
  left: 0;
  z-index: 2;
}

.return-arrow-top {
  width: 9px;
  height: 16px;
}

.colonel-privilege .colonel-privilege-container {
  width: 345px;
  /* height: 1034rpx; */
  text-align: center;
  /* box-shadow: 0px 10rpx 24rpx 2rpx rgba(0, 0, 0, 0.1); */
  /* background-color: #fff; */
  border-radius: 10px;
  margin: 10px auto 25px;
}

.colonel-privilege .icon {
  width: 66px;
  height: 66px;
  margin-top: -45px;
}

.colonel-privilege .colonel-privilege-title {
  font-size: 18px;
  font-weight: bold;
  color: #7f694e;
  line-height: 25px;
  margin: 16px auto;
}

.colonel-privilege .colonel-privilege-image {
  width: 100%;
  /* height: 704rpx; */
}

.colonel-privilege .colonel-privilege-image-2 {
  margin-top: 10px;
}

.colonel-privilege .invite-button {
  width: 310px;
  height: 45px;
  background: #FFEA1C;
  box-shadow: 0px 3px 5px 1px rgba(255, 234, 28, 0.5);
  border-radius: 25px;
  font-size: 15px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
  line-height: 21px;
  border: none;
  outline: none;
  margin: 15px 18px;
}

.invite-progress {
  width: 345px;
  /* height: 554rpx; */
  background: #fff;
  box-shadow: 0px 5px 12px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: -80px auto 0;
  /*padding: 12px 0 19px;*/
}

.invite-progress .invite-progress-title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #7f694e;
  line-height: 25px;
  /* margin-top: -77rpx; */
}

.energy-sheet {
  padding-top: 20px;
  padding-bottom: 5px;
  text-align: center;
}

.energy-sheet .title-1 {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  line-height: 25px;
}

.energy-sheet .title-2 {
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  line-height: 21px;
}

.energy-sheet .content-desc {
  width: 100%;
}

.energy-sheet .content-desc .title {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  line-height: 25px;
}

.energy-sheet .content-desc .description {
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
}

.invite-details{
  height: 100%;
  margin-top: 22px;
  text-align: center;
}
.invite-details .text-button{
  width: 90px;
  font-size: 13px;
  font-weight: 400;
  color: #7F694E;
  line-height: 18px;
}
.invite-details .arrow-right{
  margin-left: 9px;
  width: 6px;
  height: 9px;
}

.invite-progress-container {
  padding: 0 22px;
}

.invite-progress-container .progress-item {
  margin-top: 13px;
}

.invite-progress-container .progress-item:first-child {
  margin-top: 3px;
}

.invite-progress-container .progress-item .title {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invite-progress-container .progress-item .title .title-text {
  font-size: 14px;
  color: #7f694e;
  line-height: 18px;
}

.invite-progress-container .progress-item .title .number span:first-child {
  font-size: 15px;
  font-weight: bold;
  color: #7f694e;
  line-height: 21px;
}

.invite-progress-container .progress-item .title .number span:last-child {
  font-size: 13px;
  color: #333;
  line-height: 21px;
  margin-left: 4px;
}

.invite-progress-container .progress-item .progress {
  margin-top: 4px;
}

.about {
  width: 345px;
  height: 225px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 12px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 10px;
}

.about .about-title {
  font-size: 18px;
  font-weight: bold;
  color: rgba(127, 105, 78, 1);
  line-height: 25px;
  text-align: center;
  padding: 18px 0 14px;
  overflow: hidden;
}

.left-icon {
  width: 19px;
  height: 15px;
  margin: 0 -11px -10px 0;
}

.right-icon {
  width: 22px;
  height: 22px;
  margin: -10px 0 0 -11px;
}

#myVideo {
  width: 320px;
  height: 156px;
  border-radius: 10px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49px;
  font-size: 15px;
  font-weight: bold;
  color: rgba(127, 105, 78, 1);
  background-color: #fff;
  line-height: 49px;
  position: fixed;
  left: 0;
  bottom: 0;
}

.footer .wx-icon {
  width: 25px;
  height: 25px;
  margin-right: 13px;
}

.popupColonel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 33px;
  font-size: 19px;
  font-weight: bold;
}


.textContent {
  margin-top: 20px;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 21px;
}

.teacherWechat {
  margin-top: 25px;
  width: 280px;
  height: 56px;
  background: rgba(244, 244, 244, 1);
  border-radius: 10px;
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  font-size: 15px;
  font-weight: bold;
  align-items: center;
}

.copyBtn {
  margin-top: 27px;
  margin-bottom: 20px;
  width: 300px;
  height: 50px;
  background: rgba(255, 234, 28, 1);
  box-shadow: 0px 3px 5px 1px rgba(255, 234, 28, 0.5);
  border-radius: 25px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.wechatLogo {
  width: 42px;
  height: 34px;
}

.wechatNumber {
  margin-left: 10px;
  display: inline-block;
  max-width: 200px;
}
</style>
